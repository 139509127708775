import React, { useState, useEffect, useRef, useMemo } from "react"
import { cloneDeep } from "lodash"
import css from "./CalendarGridView.module.scss"
import AssignmentContentModal from "../AssignmentContentModal/AssignmentContentModal"
import { format, parseISO, isSameDay } from "date-fns"
import JobsTableModal from "../JobsTableModal/JobsTableModal"
import RequestModal from "../RequestModal"
import cx from "classnames"
import {
  Provider,
  IRequest,
  MultipleAssignmentJob,
} from "@app/containers/spa/WhiteboardCalendar/data"
import {
  ProcessedHoliday,
  getHolidaysOffDate,
} from "@app/services/getOrderedHolidays"
import { useDispatch } from "@app/models"
import { DaysOfWeek } from "@app/utils/constants"
import NotesModal from "../NotesModal"
import { getDailyScheduleChanges } from "@app/services/getDailyScheduleChanges"
import ScheduleChangesModal from "../ScheduleChangesModal"
import SingleDayGrid from "./SingleDayGrid"
import CalendarGridRow from "./CalendarGridRow"
import { TableVirtuoso } from "react-virtuoso"
import { transformsDataMultipleAssignmentJobs } from "@app/services/transformDataMultipleAssignment"
import { getDayAssignments } from "@app/utils/calendar"

interface CalendarProps {
  view: "Day" | "Week" | "Month"
  selectedDate: string
  periodCount: number
  jobsFilteredAssignments: AssignmentBaseType[]
  flags: ScheduleDateType[]
  dayoffs: Dayoff[]
  vacations: Vacation[]
  requests: IRequest[]
  providers: Provider[]
  jobs: JobAssignment[]
  filteredJobs: JobAssignment[]
  daysOffTypes: any
  requestStatus: any
  orderedHolidays: ProcessedHoliday[]
  refreshAssignments: () => void
  refreshFlags: () => void
  scheduleChanges: ScheduleChange[]
}

const getDayFlags = (flags: ScheduleDateType[], day: Date) =>
  flags?.filter((flag: ScheduleDateType) => {
    const flagDate = parseISO(flag.link_date.link_date)
    return isSameDay(flagDate, day)
  }) || []

const getDailyOffs = (dayoffs: Dayoff[], day: Date) => {
  let typedDayoffs: Record<string, Dayoff[]> = {}

  dayoffs.forEach((dayoff: Dayoff) => {
    const dayoffDate = parseISO(dayoff.date)
    if (isSameDay(dayoffDate, day)) {
      if (dayoff.type_abbrev in typedDayoffs) {
        let tmp = typedDayoffs[dayoff.type_abbrev]
        typedDayoffs[dayoff.type_abbrev] = [...tmp, dayoff]
      } else {
        typedDayoffs[dayoff.type_abbrev] = [dayoff]
      }
    }
  })

  return typedDayoffs
}

const getDailyVacations = (vacations: Vacation[], day: Date) =>
  vacations?.filter((vacation: Vacation) => {
    const vacationDate = parseISO(vacation.link_date.link_date)
    return isSameDay(vacationDate, day)
  }) || []

const getDailyRequests = (requests: IRequest[], day: Date) =>
  requests?.filter((request: IRequest) => {
    const requestStartDate = parseISO(request.start_date)
    const requestEndDate = parseISO(request.end_date)
    return day >= requestStartDate && day <= requestEndDate
  }) || []

const Calendar = ({
  view,
  selectedDate,
  periodCount,
  flags,
  dayoffs,
  vacations,
  requests,
  jobsFilteredAssignments,
  providers,
  jobs,
  filteredJobs,
  orderedHolidays,
  refreshAssignments,
  refreshFlags,
  scheduleChanges,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(parseISO(selectedDate))
  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDateOpen, setIsModalDateOpen] = useState(false)
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentBaseType>()
  const [formatDate, setFormatDate] = useState("")
  const [selectedRequestid, setSelectedRequestid] = useState<
    number | undefined
  >()
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isScheduleChangesModalOpen, setIsScheduleChangesModalOpen] =
    useState(false)
  const [selectedCurrentDate, setSelectedCurrentDate] = useState("")
  const [selectedCurrentDateStr, setSelectedCurrentDateStr] = useState("")
  const dispatch = useDispatch()

  const handleNotesModalOpen = (edate: string) => {
    setIsNotesModalOpen(true)
    const parsedDate = format(parseISO(edate), "EEEE, MM/dd/yy")
    setSelectedCurrentDate(parsedDate)
  }

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false)
    setSelectedCurrentDate("")
  }

  const handleScheduleChangesModalOpen = (edate: string) => {
    setIsScheduleChangesModalOpen(true)
    setSelectedCurrentDateStr(edate)
  }

  const handleScheduleChangesModalClose = () => {
    setIsScheduleChangesModalOpen(false)
    setSelectedCurrentDateStr("")
  }

  useEffect(() => {
    const date = parseISO(selectedDate)
    setStartDate(date)
  }, [selectedDate])

  const showModal = (data: AssignmentBaseType) => {
    setIsModalOpen(true)
    setSelectedAssignment(cloneDeep(data))
  }

  const showModalToNewAssignments = (edate: string, jobid: number) => {
    setSelectedAssignment({ jobid, edate } as AssignmentBaseType)
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setSelectedAssignment(undefined)
    dispatch.calendarEvents.getCalendarDataWithoutEvents()
    setIsModalOpen(false)
  }

  const showDateModal = (date: string) => {
    setFormatDate(date)
    setIsModalDateOpen(true)
  }

  const hideDateModal = () => {
    refreshFlags()
    setIsModalDateOpen(false)
  }

  const jobsData = useMemo(
    () =>
      formatDate &&
      transformsDataMultipleAssignmentJobs(
        getDayAssignments(jobsFilteredAssignments, formatDate),
        flags,
        filteredJobs,
        formatDate
      ),
    [jobsFilteredAssignments, flags, filteredJobs, formatDate]
  )

  const renderHeader = () => {
    if (view === "Day") {
      return null
    }
    const startDayIndex = startDate?.getDay()
    return (
      <tr>
        {[...Array(7).keys()].map((i) => {
          const dayIndex = (startDayIndex + i) % 7
          return (
            <th
              className={cx(css.headWeekday, {
                [css.headWeekend]:
                  DaysOfWeek[dayIndex].includes("Saturday") ||
                  DaysOfWeek[dayIndex].includes("Sunday"),
              })}
              key={i}
            >
              {DaysOfWeek[dayIndex]}
            </th>
          )
        })}
      </tr>
    )
  }

  const renderDays = () => {
    const endDate = new Date(startDate.getTime())
    if (view === "Day") endDate.setDate(startDate.getDate() + periodCount)
    else if (view === "Week")
      endDate.setDate(startDate.getDate() + 7 * periodCount)
    else if (view === "Month")
      endDate.setMonth(startDate.getMonth() + periodCount)

    if (view === "Day" && periodCount >= 1) {
      const daysElements: JSX.Element[] = []
      let day = new Date(startDate)
      for (let i = 0; i < periodCount; i++) {
        const header = (
          <tr key={`header-${day.toISOString()}`}>
            <th
              className={cx(css.headDay, {
                [css.headWeekend]:
                  DaysOfWeek[day.getDay()].includes("Saturday") ||
                  DaysOfWeek[day.getDay()].includes("Sunday"),
              })}
              colSpan={7}
            >
              {format(day, "EEEE, d MMMM")}
            </th>
          </tr>
        )
        daysElements.push(header)

        const dayIndex = format(day, "yyyy-MM-dd")
        const dayFlags = getDayFlags(flags, day)
        const dailyDayoffs = getDailyOffs(dayoffs, day)
        const dailyRequests = getDailyRequests(requests, day)
        const dailyVacations = getDailyVacations(vacations, day)
        const dailyScheduleChanges = getDailyScheduleChanges(
          scheduleChanges,
          day
        )
        const holidaysByDate = getHolidaysOffDate(dayIndex, orderedHolidays)

        const dayContent = (
          <tr key={day.toISOString()}>
            <SingleDayGrid
              key={dayIndex}
              day={new Date(day)}
              startDate={startDate}
              endDate={endDate}
              jobs={jobs}
              flags={dayFlags}
              dayoffs={dailyDayoffs}
              requests={dailyRequests}
              vacations={dailyVacations}
              holidays={holidaysByDate}
              dailyScheduleChanges={dailyScheduleChanges}
              showModal={showModal}
              showModalToNewAssignments={showModalToNewAssignments}
              setSelectedRequestid={setSelectedRequestid}
              handleNotesModalOpen={handleNotesModalOpen}
              showDateModal={showDateModal}
              handleScheduleChangesModalOpen={handleScheduleChangesModalOpen}
            />
          </tr>
        )
        daysElements.push(dayContent)

        day = new Date(day.setDate(day.getDate() + 1))
      }

      return (
        <table className={`table calendar-month ${css.tableContainer}`}>
          <tbody>{daysElements.map((element) => element)}</tbody>
        </table>
      )
    } else {
      const CustomTable = React.forwardRef<
        HTMLTableElement,
        React.HTMLProps<HTMLTableElement>
      >((props, ref) => {
        return (
          <table
            {...props}
            ref={ref}
            className={`table calendar-month ${css.tableContainer}`}
          />
        )
      })
      return (
        <TableVirtuoso
          data={Array.from({
            length: view === "Month" ? periodCount * 4 : periodCount,
          })}
          components={{
            Table: CustomTable,
          }}
          useWindowScroll
          itemContent={(index) => (
            <CalendarGridRow
              weekIndex={index}
              startDate={startDate}
              endDate={endDate}
              scheduleChanges={scheduleChanges}
              flags={flags}
              daysOff={dayoffs}
              vacations={vacations}
              requests={requests}
              jobs={jobs}
              orderedHolidays={orderedHolidays}
              showModal={showModal}
              showModalToNewAssignments={showModalToNewAssignments}
              setSelectedRequestid={setSelectedRequestid}
              handleNotesModalOpen={handleNotesModalOpen}
              showDateModal={showDateModal}
              handleScheduleChangesModalOpen={handleScheduleChangesModalOpen}
            />
          )}
        />
      )
    }
  }

  return (
    <div className={css.calendarContainer} ref={calendarContainerRef}>
      <div className={css.headerContainer}>
        <table className="table calendar-month">
          <thead>{renderHeader()}</thead>
        </table>
      </div>
      <div className={css.daysContainer}>{renderDays()}</div>
      {selectedAssignment && (
        <AssignmentContentModal
          show={isModalOpen}
          hideModal={hideModal}
          selectedAssignment={selectedAssignment}
          jobid={selectedAssignment.jobid}
          edate={selectedAssignment.edate}
        />
      )}
      {selectedRequestid && (
        <RequestModal
          requestid={selectedRequestid}
          onHide={() => {
            setSelectedRequestid(undefined)
            hideModal()
          }}
        />
      )}
      {isModalDateOpen && (
        <JobsTableModal
          centered={true}
          isModalDateOpen={isModalDateOpen}
          onHide={hideDateModal}
          date={formatDate}
          onCancel={hideDateModal}
          onSave={hideDateModal}
          closeButton={true}
          size="xl"
          jobsData={jobsData as unknown as MultipleAssignmentJob[]}
        />
      )}
      <NotesModal
        date={selectedCurrentDate}
        isModalOpen={isNotesModalOpen}
        onCloseModal={handleNotesModalClose}
        closeButton
      />
      <ScheduleChangesModal
        date={selectedCurrentDateStr}
        isModalOpen={isScheduleChangesModalOpen}
        onCloseModal={handleScheduleChangesModalClose}
      />
    </div>
  )
}

export default Calendar
