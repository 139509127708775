import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useLayoutEffect,
  useMemo,
  useCallback,
} from "react"
import {
  useCaniuseFeature,
  useAccessPermission,
  useIsAuthenticated,
} from "@app/utils/hooks"
import { useNavigate, useParams } from "react-router-dom"
import cx from "classnames"

import { useDispatch, useSelector, select } from "@app/models"
import { useForm } from "@app/components/Form"
import api, { mutate, useRequest } from "@app/services/api"
import BaseLayout from "@app/containers/layouts/Base"
import Menu from "@app/containers/layouts/Menu"
import CalendarListView from "./component/CalendarListView"
import Calendar from "./component/CalendarGridView"
import TabsWithSearch from "./component/TabContent"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import css from "./WhiteboardCalendar.module.scss"
import { format, addDays, parseISO } from "date-fns"
import Footer from "@app/containers/layouts/Footer"
import ManagePreferences from "./component/ManagePreferences/ManagePreferences"
import FilterProviders from "./component/SubMenuCalendar/components/FilterProviders"
import FilterJobs from "./component/SubMenuCalendar/components/FilterJobs"
import FilterDaysOff from "./component/SubMenuCalendar/components/FilterDaysOff"
import CalendarControls from "./component/CalendarControls"
import QuickAssignmentMenu from "./component/QuickAssignmentMenu"
import { IRequestStatus, IRequestType } from "./data"
import { getOrderedHolidays } from "@app/services/getOrderedHolidays"
import { getEarliestWeekDayBefore } from "@app/utils"
import { DaysOfWeek, DaysOfWeekType } from "@app/utils/constants"
import HighlightProviders from "./component/SubMenuCalendar/components/HighlightProviders"
import FilterMissingAssignments from "./component/SubMenuCalendar/components/FilterMissingAssignments"
import { orderJobsFunc, orderProvidersFunc } from "@app/utils/sorts"
import HighlightUnderstaffedJobs from "./component/SubMenuCalendar/components/HighlightUnderstaffedJobs"
import SaveTemplate from "./component/SubMenuCalendar/components/SaveTemplate"
import Unschedule from "./component/SubMenuCalendar/components/Unschedule"
import EraseFlags from "./component/SubMenuCalendar/components/EraseFlags"
import ScheduleRange from "./component/SubMenuCalendar/components/ScheduleRange"
import HighlightChanges from "./component/SubMenuCalendar/components/HighlightChanges"
import { dailyGroupedAssignments } from "@app/services/getGroupedDayAssignments"

type CalendarView = "Day" | "Week" | "Month"

type FormFields = {
  date: string
  number: number
  view: "Day" | "Week" | "Month"
}

type ViewMode = "calendar" | "list"

type ParamsType = {
  view: ViewMode
}

const WhiteboardCalendar: React.FC = () => {
  const { view = "calendar" } = useParams<ParamsType>()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.calendarEvents.setCalendarConfig({ calendarType: view })
  }, [view])

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
      setActualDate(format(new Date(), "yyyy-MM-dd"))

      dispatch.calendarEvents.getSchedulingRules()
      dispatch.calendarEvents.getFilterOptions()
      dispatch.calendarEvents.getRequestStatusAndType()
    })()
  }, [])

  const { groupid, group } = useSelector((state) => state.users.currentUser)

  const { requestStatus, requestTypes } = useSelector(
    (state) => state.calendarEvents
  )

  const {
    filterOptions,
    rulesConfig: { calendar_start_date },
    isMissingAssignmentsFilterActive,
    isUnderstaffedJobsHighlightActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  useEffect(() => {
    const { dayoffFilters } = filterOptions

    if (
      requestStatus.length > 0 &&
      requestTypes.length > 0 &&
      dayoffFilters.requestTypeIds === undefined &&
      dayoffFilters.requestStatusIds === undefined &&
      dayoffFilters.showVacations === undefined
    ) {
      const requestStatusIds = requestStatus
        .filter((status: IRequestStatus) =>
          ["Pending", "Provisional"].includes(status.status)
        )
        .map((status: IRequestStatus) => status.request_statusid)
      const requestTypeIds = requestTypes.map(
        (rt: IRequestType) => rt.request_typeid
      )

      dispatch.calendarEvents.setDayoffFilters({
        requestStatusIds,
        requestTypeIds,
        showVacations: true,
      })
    }
  }, [requestStatus, requestTypes])

  const calendarViewStartDay = DaysOfWeek[
    calendar_start_date - 1
  ] as DaysOfWeekType

  const isAuthenticated = useIsAuthenticated()
  const calendarV2Enabled = useCaniuseFeature("calendar_v2", { scope: "group" })
  const { canAccess } = useAccessPermission({ scope: "calendarV2" })
  const menuRef = useRef<HTMLDivElement>(null)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const tabsRef = useRef<HTMLDivElement>(null)
  const [currentView, setCurrentView] = useState<CalendarView>("Month")
  const [selectedDate, setSelectedDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  )

  const [actualDate, setActualDate] = useState<string>()
  const [dropdownShow, setDropdownShow] = useState(false)
  const [quickAssigneePanelOpen, setQuickAssigneePanelOpen] = useState(false)
  const [showFooter, setShowFooter] = useState(false)
  const [initialCheckDone, setInitialCheckDone] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [number, setNumber] = useState(1)
  const [period, setPeriod] = useState("Month")
  const [periodCount, setPeriodCount] = useState<number>(1)
  const [daysOptions, setDaysOptions] = useState<number[]>(
    [...Array(13).keys()].map((n) => n + 1)
  )
  const [groupName, setGroupName] = useState<string>("")
  const [startDate, setStartDate] = useState(() => {
    if ((view === undefined || view === "calendar") && period !== "Day") {
      const newStartDate = getEarliestWeekDayBefore(
        selectedDate,
        calendarViewStartDay
      )
      return format(newStartDate, "yyyy-MM-dd")
    } else {
      return selectedDate
    }
  })

  const [endDate, setEndDate] = useState("")
  const [activeSubmenu, setActiveSubmenu] = useState("")
  const [showUnschedule, setShowUnschedule] = useState(false)
  const [showEraseFlags, setShowEraseFlags] = useState(false)
  const [showScheduleRange, setShowScheduleRange] = useState(false)

  const { data: events, isLoading: eventsLoading } = useRequest(
    startDate && endDate ? [api.getAssignments, startDate, endDate] : null
  )

  const { data: draftEventsData, isLoading: draftEventsLoading } = useRequest(
    startDate && endDate ? [api.getDraftEvents, startDate, endDate] : null
  )

  const { data: flagsData, isLoading: flagsLoading } = useRequest(
    startDate && endDate ? [api.getFlags, startDate, endDate] : null
  )

  const { data: dayoffs, isLoading: dayoffsLoading } = useRequest(
    startDate && endDate ? [api.getDayoffs, startDate, endDate] : null
  )

  const { data: notesData } = useRequest(
    startDate && endDate ? [api.getNotesList, startDate, endDate] : null
  )

  const { data: vacationsData, isLoading: vacationsLoading } = useRequest(
    startDate && endDate ? [api.getVacations, startDate, endDate] : null
  )

  const { data: pendingRequests, isLoading: requestsLoading } = useRequest(
    startDate && endDate ? [api.getPendingRequests, startDate, endDate] : null
  )

  const { data: jobsData, isLoading: jobsDataLoading } = useRequest([
    api.getJobs,
  ])

  const { data: scheduleChanges } = useRequest(
    startDate && endDate ? [api.getScheduleChanges, startDate, endDate] : null
  )

  const {
    data: multipleAssignmentSettings,
    isLoading: multipleAssignmentSettingsLoading,
  } = useRequest(
    startDate && endDate
      ? [api.getJobsMultipleAssignmentSettings, startDate, endDate]
      : null
  )

  const { data: providersData, isLoading: providersDataLoading } = useRequest(
    groupid ? [api.getGroupProviders, groupid] : null
  )

  const { data: holidaysData, isLoading: holidaysDataLoading } = useRequest([
    api.getHolidays,
  ])

  const sortedJobsData = useMemo(
    () => (jobsData ? [...jobsData].sort(orderJobsFunc) : []),
    [jobsData]
  )
  const sortedProvidersData = useMemo(
    () => (providersData ? [...providersData].sort(orderProvidersFunc) : []),
    [providersData]
  )

  useEffect(() => {
    calculateAndSetDates(
      selectedDate,
      period,
      periodCount,
      calendarViewStartDay,
      view
    )
  }, [selectedDate, period, periodCount, view, calendarViewStartDay])

  useEffect(() => {
    if (events) {
      dispatch.calendarEvents.setEvents({ assignments: events })
      dispatch.calendarEvents.setDailyEvents(
        dailyGroupedAssignments(events, (e) => e.edate, startDate, endDate)
      )
    }
    if (draftEventsData) {
      dispatch.calendarEvents.setEvents({ draftEvents: draftEventsData })
      dispatch.calendarEvents.setDailyDraftEvents(
        dailyGroupedAssignments(
          draftEventsData,
          (e) => e.edate,
          startDate,
          endDate
        )
      )
    }
    if (flagsData) {
      dispatch.calendarEvents.setEvents({ flags: flagsData })
    }
    if (dayoffs) {
      dispatch.calendarEvents.setEvents({ daysOff: dayoffs })
    }
    if (pendingRequests) {
      dispatch.calendarEvents.setEvents({ requests: pendingRequests })
    }
    if (vacationsData) {
      dispatch.calendarEvents.setEvents({ vacations: vacationsData })
    }
    if (holidaysData) {
      dispatch.calendarEvents.setEvents({ holidays: holidaysData })
    }

    if (jobsData) {
      dispatch.groupData.setGroupData({
        jobs: sortedJobsData,
      })
    }

    if (providersData) {
      dispatch.groupData.setGroupData({
        providers: sortedProvidersData,
      })
    }

    if (multipleAssignmentSettings) {
      dispatch.groupData.setJobsMultipleAssignmentsSettings(
        multipleAssignmentSettings
      )
    }
  }, [
    events,
    draftEventsData,
    flagsData,
    dayoffs,
    pendingRequests,
    vacationsData,
    holidaysData,
    jobsData,
    providersData,
    multipleAssignmentSettings,
  ])

  useEffect(() => {
    if (notesData) {
      dispatch.calendarEvents.setEvents({ notes: notesData })
      dispatch.calendarEvents.setDailyNotes(
        dailyGroupedAssignments(notesData, (note: Note) => note.note_date)
      )
    }
  }, [notesData])

  const {
    flags,
    daysOff,
    requests,
    vacations,
    holidays,
    notes,
    jobsFilteredAssignments,
    jobsFilteredDraftEvents,
  } = useSelector(select.calendarEvents.filteredCalendarData)

  const orderedHolidays = getOrderedHolidays(holidays, selectedDate)

  const dayOffTypes = requestTypes
    ? requestTypes
        .filter((requestType: IRequestType) =>
          Boolean(requestType.dayoff_typeid)
        )
        .map((requestType: IRequestType) => requestType.dayoff_type!)
    : []

  const availableRequestStatus = requestStatus
    ? requestStatus.filter(
        (status: IRequestStatus) =>
          status.status === "Pending" || status.status === "Provisional"
      )
    : []

  const handleNumberChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNumber(Number(e.target.value))
  }

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPeriod = e.target.value
    setPeriod(newPeriod)

    let newView: CalendarView = "Month"
    if (newPeriod === "Day") {
      newView = "Day"
    } else if (newPeriod === "Week") {
      newView = "Week"
    } else if (newPeriod === "Month") {
      newView = "Month"
    }

    setCurrentView(newView)
  }

  const handleViewModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/calendar/v2/${e.target.value}`)
  }

  useEffect(() => {
    if (group) {
      setGroupName(group.name)
    }
  }, [groupid, selectedDate])

  async function getAssignments() {
    dispatch.calendarEvents.getCalendarData()
  }

  const toggleFooter = () => {
    setShowFooter(!showFooter)
  }

  useEffect(() => {
    if (!initialCheckDone && isAuthenticated === false) {
      setInitialCheckDone(true)
      return
    }

    if (isAuthenticated) {
      if (!calendarV2Enabled || !canAccess("/calendar/v2")) {
        window.location.href = "/menu.cgi"
      } else {
        setIsLoading(false)
      }
    }
  }, [isAuthenticated, calendarV2Enabled, canAccess, initialCheckDone])

  useLayoutEffect(() => {
    if (showFooter && tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [showFooter])

  useEffect(() => {
    if (period === "Day") {
      setDaysOptions([...Array(31).keys()].map((n) => n + 1))
    } else {
      setDaysOptions([...Array(13).keys()].map((n) => n + 1))
    }
  }, [period])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownShow &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setDropdownShow(false)
      }
    }

    if (dropdownShow) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [dropdownShow])

  const toggleDropdown = () => setDropdownShow(!dropdownShow)

  const { control, handleSubmit } = useForm<FormFields>({
    schema: (yup) =>
      yup.object().shape({
        date: yup.string().required(),
      }),
  })

  const onSubmit = (fields: FormFields): void => {
    setSelectedDate(fields.date)
    setCurrentView(period as CalendarView)
    setPeriodCount(number)
  }

  const calculateAndSetDates = (
    selectedDate: string,
    period: string,
    count: number,
    calendarViewStartDay: DaysOfWeekType,
    view?: ViewMode
  ) => {
    const start = parseISO(selectedDate)
    let newStart = undefined
    let end

    switch (period) {
      case "Day":
        end = addDays(start, count)
        break
      case "Week":
        end = addDays(start, count * 7)
        break
      case "Month":
        end = addDays(start, count * 40)
        break
      default:
        throw new Error("Invalid period")
    }
    const shouldCalculateNewStartDate =
      (view === undefined || view === "calendar") && period !== "Day"

    if (shouldCalculateNewStartDate) {
      const newStartDate = getEarliestWeekDayBefore(
        selectedDate,
        calendarViewStartDay
      )
      setStartDate(format(newStartDate, "yyyy-MM-dd"))
      newStart = newStartDate
    } else {
      setStartDate(format(start, "yyyy-MM-dd"))
    }

    setEndDate(format(end, "yyyy-MM-dd"))

    dispatch.calendarEvents.setFilterOptions({
      startDate: format(newStart ?? start, "yyyy-MM-dd"),
      endDate: format(end, "yyyy-MM-dd"),
    })
  }

  const openSubmenu = (submenuName: string) => {
    setActiveSubmenu(submenuName)
  }

  const closeSubmenu = () => {
    setActiveSubmenu("")
  }
  const resetQuickMode = useCallback(() => {
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }, [dispatch])

  const onShowQuickAssigneePanel = () => {
    setQuickAssigneePanelOpen(!quickAssigneePanelOpen)
  }

  const onShowUnschedule = () => {
    setShowUnschedule(true)
  }

  const onShowEraseFlags = () => {
    setShowEraseFlags(true)
  }

  const onShowScheduleRange = () => {
    setShowScheduleRange(true)
  }

  const handleCloseQuickAssigneePanel = () => {
    resetQuickMode()
  }

  const jobsFilteredData = useMemo(() => {
    const { jobIds } = filterOptions.jobsFilters
    const hasJobIds = Boolean(jobIds)

    const isJobItemMatching = (jobItem: JobAssignment) => {
      const hasMatchingJobId = jobIds?.includes(jobItem.jobid)
      const hasEmptyAssignments =
        jobItem.show_empty_assignments &&
        jobItem.job_day_types.some((dayType) => dayType.assign === 1)
      const hasMultipleAssignmentsEnabled = jobItem.multiple_assignments

      if (
        isMissingAssignmentsFilterActive &&
        hasJobIds &&
        isUnderstaffedJobsHighlightActive
      ) {
        return (
          hasEmptyAssignments &&
          hasMatchingJobId &&
          hasMultipleAssignmentsEnabled
        )
      } else if (isMissingAssignmentsFilterActive && hasJobIds) {
        return hasEmptyAssignments && hasMatchingJobId
      } else if (
        isMissingAssignmentsFilterActive &&
        isUnderstaffedJobsHighlightActive
      ) {
        return hasEmptyAssignments && hasMultipleAssignmentsEnabled
      } else if (hasJobIds && isUnderstaffedJobsHighlightActive) {
        return hasMatchingJobId && hasMultipleAssignmentsEnabled
      } else if (isMissingAssignmentsFilterActive) {
        return hasEmptyAssignments
      } else if (isUnderstaffedJobsHighlightActive) {
        return hasMultipleAssignmentsEnabled
      }

      return hasMatchingJobId
    }

    const filteredJobs =
      hasJobIds ||
      isMissingAssignmentsFilterActive ||
      isUnderstaffedJobsHighlightActive
        ? sortedJobsData.filter(isJobItemMatching)
        : sortedJobsData

    return filteredJobs
  }, [
    filterOptions.jobsFilters.jobIds,
    jobsData,
    isMissingAssignmentsFilterActive,
    isUnderstaffedJobsHighlightActive,
  ])

  const providersFilteredData = useMemo(() => {
    const filtered = filterOptions.providersFilters.providerIds
      ? sortedProvidersData.filter((providerItem: Provider) =>
          filterOptions.providersFilters.providerIds!.includes(
            providerItem.providerid
          )
        )
      : sortedProvidersData

    return filtered
  }, [filterOptions.providersFilters.providerIds, providersData])

  const renderSubmenuContent = () => {
    switch (activeSubmenu) {
      case "Manage Scheduling Preferences":
        return <ManagePreferences onClose={closeSubmenu} />
      case "Select Providers to Display":
        return (
          <FilterProviders
            providersData={sortedProvidersData}
            onClose={closeSubmenu}
          />
        )
      case "Select Providers to Highlight":
        return (
          <HighlightProviders
            providersData={sortedProvidersData}
            onClose={closeSubmenu}
          />
        )
      case "Select Jobs to Display":
        return <FilterJobs jobsData={sortedJobsData} onClose={closeSubmenu} />
      case "Select Request (Day Off) Types to Display":
        return (
          <FilterDaysOff
            onClose={closeSubmenu}
            requestStatus={availableRequestStatus}
            requestTypes={requestTypes}
          />
        )
      case "Missing Assignments":
        return <FilterMissingAssignments />
      case "Assignment Requirements":
        return <HighlightUnderstaffedJobs />
      case "Highlight Changes":
        return <HighlightChanges />
      case "New Template":
        return <SaveTemplate />
      default:
        return null
    }
  }

  if (isLoading) {
    return (
      <div className={css.loadingContainer}>
        <div className={css.spinner}></div>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <Fragment>
      <Menu />
      <BaseLayout
        containerSize="fluid"
        className={cx("mt-0 mb-0", css.calendarWrapper)}
      >
        <CalendarControls
          actualDate={actualDate || ""}
          control={control}
          number={number}
          period={period}
          viewMode={view || "calendar"}
          daysOptions={daysOptions}
          handleNumberChange={handleNumberChange}
          handlePeriodChange={handlePeriodChange}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          handleViewModeChange={handleViewModeChange}
          toggleDropdown={toggleDropdown}
          menuButtonRef={menuButtonRef}
          groupName={groupName}
          dropdownShow={dropdownShow}
          activeSubmenu={activeSubmenu}
          openSubmenu={openSubmenu}
          closeSubmenu={closeSubmenu}
          renderSubmenuContent={renderSubmenuContent}
          onShowQuickAssigneePanel={onShowQuickAssigneePanel}
          setQuickAssigneePanelOpen={setQuickAssigneePanelOpen}
          quickAssigneePanelOpen={quickAssigneePanelOpen}
          onShowUnschedule={onShowUnschedule}
          onShowEraseFlags={onShowEraseFlags}
          onShowScheduleRange={onShowScheduleRange}
          menuRef={menuRef}
          providersData={sortedProvidersData}
          jobsData={sortedJobsData}
          requestStatus={availableRequestStatus}
          requestTypes={requestTypes}
        />
        <Fragment>
          {providersDataLoading ||
          jobsDataLoading ||
          dayoffsLoading ||
          vacationsLoading ||
          requestsLoading ||
          flagsLoading ||
          holidaysDataLoading ||
          multipleAssignmentSettingsLoading ||
          eventsLoading ||
          draftEventsLoading ? (
            <div className={css.calendarLoading}>
              <div className={css.spinner}></div>
              <div>Loading...</div>
            </div>
          ) : (
            <Fragment>
              {!view || view === "calendar" ? (
                <Calendar
                  view={currentView}
                  selectedDate={startDate}
                  periodCount={periodCount}
                  flags={flags}
                  dayoffs={daysOff}
                  vacations={vacations}
                  requests={requests}
                  providers={sortedProvidersData}
                  jobsFilteredAssignments={[
                    ...jobsFilteredAssignments,
                    ...jobsFilteredDraftEvents,
                  ]}
                  refreshAssignments={() => getAssignments()}
                  refreshFlags={() =>
                    mutate([api.getFlags, startDate, endDate])
                  }
                  jobs={sortedJobsData}
                  filteredJobs={jobsFilteredData}
                  daysOffTypes={dayOffTypes}
                  requestStatus={requestStatus}
                  orderedHolidays={orderedHolidays}
                  scheduleChanges={scheduleChanges}
                />
              ) : (
                <div className={css.calendarListViewContainer}>
                  <CalendarListView
                    view={currentView}
                    notes={notes}
                    selectedDate={selectedDate}
                    periodCount={periodCount}
                    flags={flags}
                    dayoffs={daysOff}
                    requests={requests}
                    vacations={vacations}
                    jobsData={jobsFilteredData}
                    providersData={providersFilteredData}
                    refreshAssignments={() => getAssignments()}
                    refreshFlags={() =>
                      mutate([api.getFlags, startDate, endDate])
                    }
                    daysOffTypes={dayOffTypes}
                    requestStatus={requestStatus}
                    orderedHolidays={orderedHolidays}
                    quickMode={quickAssigneePanelOpen}
                    setQuickAssigneePanelOpen={setQuickAssigneePanelOpen}
                    scheduleChanges={scheduleChanges}
                  />

                  {quickAssigneePanelOpen ? (
                    <QuickAssignmentMenu
                      providersData={sortedProvidersData}
                      jobsData={sortedJobsData}
                      quickAssigneePanelOpen={quickAssigneePanelOpen}
                      onShowQuickAssigneePanel={onShowQuickAssigneePanel}
                      onClose={handleCloseQuickAssigneePanel}
                    />
                  ) : null}
                </div>
              )}
              <ScheduleRange
                showScheduleRange={showScheduleRange}
                onHide={setShowScheduleRange}
              />
              <Unschedule
                showUnschedule={showUnschedule}
                onHide={setShowUnschedule}
              />
              <EraseFlags
                showEraseFlags={showEraseFlags}
                onHide={setShowEraseFlags}
              />
            </Fragment>
          )}
        </Fragment>

        <div ref={tabsRef}>
          <div
            className={`${css.footerToggle} ${
              showFooter ? css.footerExpanded : ""
            }`}
            onClick={toggleFooter}
          >
            <span className={css.footerExpandedSpan}>SCHEDULING LEGENDS</span>
            {showFooter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {showFooter && (
            <TabsWithSearch
              providers={sortedProvidersData}
              jobs={sortedJobsData}
              dayOffTypes={dayOffTypes}
            />
          )}
        </div>

        <Footer />
      </BaseLayout>
    </Fragment>
  )
}

export default WhiteboardCalendar
